// assets
import {
  DashboardOutlined,
  ChromeOutlined,
  ProfileOutlined,
  QuestionOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  LoginOutlined,
  AccountBookOutlined,
  UserAddOutlined,
  SettingOutlined,
  ProductOutlined
} from '@ant-design/icons'
// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined,
  QuestionOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ProfileOutlined,
  LoginOutlined,
  AccountBookOutlined,
  AdminPanelSettingsIcon,
  TimelineIcon,
  UserAddOutlined,
  SettingOutlined,
  ProductOutlined
}
import StorefrontIcon from '@mui/icons-material/Storefront'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import AddShoppingCart from '@mui/icons-material/AddShoppingCart'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import TimelineIcon from '@mui/icons-material/Timeline'
import CategoryIcon from '@mui/icons-material/Category'
import GavelIcon from '@mui/icons-material/Gavel'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const MemberMenu = {
  id: 'memberMenu',
  title: 'Main',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'appointmentsRoot',
      title: 'APPOINTMENTS',
      type: 'collapse',
      icon: ScheduleOutlined,
      children: [
        {
          id: 'schedule',
          title: 'Schedule',
          type: 'item',
          url: '/schedule',
          breadcrumbs: false,
          icon: EventAvailableOutlinedIcon
        },
        {
          id: 'appointment',
          title: 'Calendar',
          type: 'item',
          url: '/appointment',
          breadcrumbs: false,
          icon: CalendarMonthOutlinedIcon
        }
      ]
    },
    {
      id: 'financesRoot',
      title: 'FINANCES',
      type: 'collapse',
      icon: MonetizationOnOutlinedIcon,
      children: [
        {
          id: 'bills',
          title: 'Bills',
          type: 'item',
          url: '/bills',
          breadcrumbs: false,
          icon: AttachMoneyOutlinedIcon
        },
        {
          id: 'outstanding',
          title: 'Outstanding',
          type: 'item',
          url: '/outstanding',
          breadcrumbs: false,
          icon: PaymentOutlinedIcon
        },
        {
          id: 'credits',
          title: 'Family Accounts',
          type: 'item',
          url: '/credits',
          breadcrumbs: false,
          icon: CardGiftcardOutlinedIcon
        }
      ]
    },
    {
      id: 'ordersRoot',
      title: 'ORDERS',
      type: 'collapse',
      icon: AddShoppingCart,
      children: [
        {
          id: 'orders',
          title: 'Customer Orders',
          type: 'item',
          url: '/orders',
          breadcrumbs: false,
          icon: ShoppingCartOutlinedIcon
        }
      ]
    },
    {
      id: 'licenceRoot',
      title: 'LICENCE',
      type: 'collapse',
      icon: DescriptionOutlinedIcon,
      children: [
        {
          id: 'receipts',
          title: 'Receipts',
          type: 'item',
          url: '/receipts',
          icon: ReceiptLongIcon
        },
        {
          id: 'licences',
          title: 'Licences',
          type: 'item',
          url: '/licences',
          icon: GavelIcon
        }
      ]
    },
    {
      id: 'clientRoot',
      title: 'CLIENTS',
      type: 'collapse',
      icon: PermIdentityIcon,
      children: [
        {
          id: 'clients',
          title: 'Clients',
          type: 'item',
          icon: PersonOutlineIcon,
          url: '/clients'
        },
        {
          id: 'treatmentHistory',
          title: 'Treatment History',
          type: 'item',          
          url: '/treatmentHistory'
        },
        {
          id: 'orderHistory',
          title: 'Order History',
          type: 'item',          
          url: '/orderHistory'
        },
        {
          id: 'receiptHistory',
          title: 'Receipt History',
          type: 'item',          
          url: '/receiptHistory'
        },
        {
          id: 'billHistory',
          title: 'Bill History',
          type: 'item',          
          url: '/billHistory'
        },
        {
          id: 'paymentHistory',
          title: 'Payment History',
          type: 'item',          
          url: '/paymentHistory'
        },
        {
          id: 'creditHistory',
          title: 'Account History',
          type: 'item',          
          url: '/creditHistory'
        }
      ]
    },
    {
      id: 'staffRoot',
      title: 'STAFFS',
      type: 'collapse',
      icon: icons.UsergroupAddOutlined,
      children: [
        {
          id: 'users',
          title: 'Staffs',
          type: 'item',
          icon: GroupAddOutlinedIcon,
          url: '/users'
        }
      ]
    },
    {
      id: 'servicesRoot',
      title: 'SERVICES/PRODUCTS',
      type: 'collapse',
      icon: ProductOutlined,
      children: [
        {
          id: 'categories',
          title: 'Category',
          type: 'item',
          url: '/categories',
          icon: CategoryIcon
        },
        {
          id: 'products',
          title: 'Products',
          type: 'item',
          url: '/products',
          icon: StorefrontIcon
        }
      ]
    },
    {
      id: 'wagesRoot',
      title: 'WAGES',
      type: 'collapse',
      icon: AccountBookOutlined,
      children: [
        {
          id: 'wages',
          title: 'wages',
          type: 'item',
          url: '/wages',
          breadcrumbs: false
        },
        {
          id: 'wageBoard',
          title: 'Wages',
          type: 'item',
          url: '/wageBoard',
          breadcrumbs: false
        },
        {
          id: 'timesheets',
          title: 'Timesheets',
          type: 'item',
          url: '/timesheets',
          breadcrumbs: false
        },
        {
          id: 'timesheetBoard',
          title: 'Timesheets',
          type: 'item',
          url: '/timesheetBoard',
          breadcrumbs: false
        },
        {
          id: 'commission',
          title: 'Commission',
          type: 'item',
          url: '/commission',
          breadcrumbs: false
        },
        {
          id: 'commissionBoard',
          title: 'Commission',
          type: 'item',
          url: '/commissionBoard',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'receptionistZoneRoot',
      title: 'RECEPTIONIST ZONE',
      type: 'collapse',
      icon: WifiCalling3OutlinedIcon,
      children: [
        {
          id: 'cashAccounting',
          title: 'Cash Accounting',
          type: 'item',
          url: '/cashAccounting',
          icon: CurrencyExchangeOutlinedIcon,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'managerZone',
      title: 'MANAGER ZONE',
      type: 'collapse',
      icon: icons.AdminPanelSettingsIcon,
      children: [
        {
          id: 'expenses',
          title: 'Expense',
          type: 'item',
          url: '/expenses',
          breadcrumbs: false
        },
        {
          id: 'sales',
          title: 'Recent sales',
          type: 'item',
          url: '/sales',
          breadcrumbs: false
        },
        {
          id: 'managerReport',
          title: 'Financial Reports',
          type: 'item',
          url: '/managerReport',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'systemSetting',
      title: 'System Setting',
      type: 'item',
      url: '/systemSetting',
      icon: icons.SettingOutlined
    },
    {
      id: 'GUIDES',
      title: 'GUIDES',
      type: 'collapse',
      icon: MenuBookOutlinedIcon,
      children: [
        {
          id: 'help',
          title: 'Forms',
          type: 'item',
          url: '/help',
          breadcrumbs: false,
          icon: MenuBookOutlinedIcon
        }
      ]
    },
    {
      id: 'logout',
      title: 'LogOut',
      type: 'item',
      url: '/logout',
      icon: icons.LoginOutlined
    }
  ]
}

export default MemberMenu
